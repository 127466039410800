import React from 'react'
import Hero from '../HomeSection/Hero'
import About from '../About/About'
import Hotels from '../Hotels/Hotels'
import Footer from '../footer/Footer'





const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Hotels />
      <Footer />
      
    </>
  )
}

export default Home
