import { DiRuby } from "react-icons/di";

const GalleryData = [
    {
      img: "pexels-chan-walrus-941861.jpg",
      
    },
    {
      img: "pexels-karl-solano-2883047.jpg",
     
    },
    {
      img: "pexels-pixabay-164595.jpg",
      
    },
    {
      img: "pexels-pixabay-258154.jpg",
      
    },
    {
      img: "pexels-quark-studio-2507010.jpg",
      
    },
    {
      img: "pexels-recal-media-60217.jpg",
      
    },
    {
      img: "pexels-timo-volz-1842332.jpg",
      
    },
    {
      img: "pexels-thorsten-technoman-338504.jpg",
      
    },
    {
      img: "pexels-elevate-1267320.jpg",
      
    },
    {
      img: "pexels-aleksandar-pasaric-1164675.jpg",
      
    },
    {
      img: "pexels-quark-studio-3201922.jpg",
      
    },
    {
      img: "pexels-naim-benjelloun-2287523.jpg",
      
    },
  ]
  
  export default GalleryData